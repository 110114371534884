<template>
    <div class="flex text-gray-700">
        <button v-if="paginate.currentpage > 1" @click="paginatation('prev')" :disabled="paginate.currentpage == 1" class="h-8 w-8 focus:outline-none mr-6 flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer">
            <i class="material-icons"> keyboard_arrow_left </i>
        </button>
        <div class="flex h-8 font-medium rounded-full bg-gray-200">
            <div v-if="paginate.currentpage != paginate.lastpage" class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-green-600  text-white">
                {{ paginate.currentpage }}
            </div>
            <div v-else class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full">
                <span v-if="paginate.currentpage != 1"> 1 </span>
            </div>
            <div v-if="paginate.currentpage >= 1 && paginate.lastpage != 1" class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full">...</div>
            <button :class=" paginate.currentpage == paginate.lastpage ? 'bg-green-600  text-white' : ' '" class="w-8 md:flex justify-center items-center hover:bg-green-600  hover:text-white focus:outline-none hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full">{{ paginate.lastpage }}</button>
        </div>
        <button v-if="paginate.currentpage < paginate.lastpage" :disabled="paginate.currentpage == paginate.lastpage" @click="paginatation('next')" class="h-8 w-8 ml-6 focus:outline-none flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"><i class="material-icons"> keyboard_arrow_right </i></button>             
    </div>
</template>


<script>
export default {
     props:{
        paginate: { type: Object },
    },

    methods:{
         paginatation: function (val) {
            this.$emit('paginatation',val);
        },
    }
    
}
</script>


<style >

</style>